import { Bars2Icon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface IKPI {
  percentage: number;
  target: number;
  icon: 'up' | 'down' | 'equal';
}

export interface KPIProps {
  title: string;
  legend: string;
  data: string | IKPI;
  pointPerAchievement?: string;
}

function Icon({ type }: { type: 'up' | 'down' | 'equal' }) {
  if (type === 'up') {
    return <CheckIcon className="w-4 h-4 text-green-600" />;
  }
  if (type === 'down') {
    return <XMarkIcon className="w-4 h-4 text-red-600" />;
  }
  return <Bars2Icon className="w-4 h-4 text-yellow-600" />;
}

export default function KPI({
  title,
  legend,
  data,
  pointPerAchievement = '1,000',
}: KPIProps) {
  if (typeof data === 'string') {
    return (
      <div className="rounded-lg border bg-white text-slate-700 shadow-sm">
        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
          <h3 className="tracking-tight text-sm font-medium">{title}</h3>
        </div>
        <div className="p-6 pt-0">
          <div className="text-2xl font-bold">{data}</div>
          <p className="text-xs text-slate-400">{legend}</p>
          <p className="text-xs text-transparent">.</p>
        </div>
      </div>
    );
  }
  return (
    <div className="rounded-lg border bg-white text-slate-700 shadow-sm">
      <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
        <h3 className="tracking-tight text-sm font-medium">{title}</h3>
        <Icon type={data?.icon ?? 'equal'} />
      </div>
      <div className="p-6 pt-0">
        <div className="text-2xl font-bold">
          {data?.percentage > 1
            ? `${data?.percentage ?? 0}%`
            : `${Number((data?.percentage ?? 0) * 100).toFixed(0)}%`}
        </div>
        <p className="text-xs text-slate-400">{legend}</p>
        <p className="text-xs text-slate-400">
          {pointPerAchievement} ptos por cumplimiento
        </p>
      </div>
    </div>
  );
}
