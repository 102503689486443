/* eslint-disable import/no-cycle */
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_INFO } from '../../graphql/queries';
import Layout from '../../components/Layout';
import Dashboard, { DashboardData } from '../../components/dashboard/Dashboard';
import GraphLoader from '../../components/graphs/GraphLoader';

export default function AppPage() {
  const { data, loading, error } = useQuery<{ clientDashboard: DashboardData }>(
    GET_DASHBOARD_INFO,
    { fetchPolicy: 'network-only' }
  );
  return (
    <Layout>
      {loading ? (
        <div className="w-full h-full flex">
          <div className="m-auto">
            <GraphLoader />
          </div>
        </div>
      ) : (
        <Dashboard data={data?.clientDashboard} />
      )}
    </Layout>
  );
}
