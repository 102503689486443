import React from 'react';
import { Benefit } from '../../models';
import formatMoney from '../../lib/formatMoney';

interface BenefitProps {
  benefit: Benefit;
  currentPoints: number;
}

function BenefitRow({ benefit, currentPoints }: BenefitProps) {
  return (
    <div className="flex items-center">
      <span className="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9">
        <img
          className="aspect-square h-full w-full"
          alt="Avatar"
          src={benefit?.photos?.[0] ?? '/images/avatar.png'}
        />
      </span>
      <div className="ml-4 space-y-1">
        <p className="text-sm font-medium leading-none">
          {benefit?.name ?? ''}
        </p>
        <p className="text-sm text-muted-foreground">
          {formatMoney(benefit?.points)}
        </p>
      </div>
      <div className="ml-auto font-medium">
        {currentPoints >= benefit?.points
          ? 'Canjealo ahora'
          : `Aún faltan ${formatMoney(
              (benefit?.points ?? 0) - currentPoints
            )} puntos`}
      </div>
    </div>
  );
}

interface BenefitsProps {
  benefits: Array<Benefit>;
  currentPoints: number;
}

export default function Benefits({ benefits, currentPoints }: BenefitsProps) {
  return (
    <div className="rounded-lg border bg-white text-slate-600 shadow-sm col-span-3">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="text-lg font-semibold leading-none tracking-tight">
          Beneficios
        </h3>
        <p className="text-sm text-muted-foreground">
          Estos son los últimos 3 beneficios que se han agregado
        </p>
      </div>
      <div className="p-6 pt-0">
        <div className="space-y-8">
          {benefits.map((contact) => (
            <BenefitRow
              benefit={contact}
              currentPoints={currentPoints}
              key={contact?._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
