import React from 'react';
import { User } from '../../models';

interface ContactProps {
  contact: User;
}

function Contact({ contact }: ContactProps) {
  return (
    <div className="flex items-center">
      <span className="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9">
        <img
          className="aspect-square h-full w-full"
          alt="Avatar"
          src={contact?.photo ?? '/images/avatar.png'}
        />
      </span>
      <div className="ml-4 space-y-1">
        <p className="text-sm font-medium leading-none">
          {contact?.firstName ?? ''} {contact?.lastName ?? ''}
        </p>
        <p className="text-sm text-muted-foreground">{contact?.email ?? ''}</p>
      </div>
      <div className="ml-auto font-medium">{contact?.phone?.mobile ?? ''}</div>
    </div>
  );
}

interface ContactsProps {
  contacts: Array<User>;
}

export default function Contacts({ contacts }: ContactsProps) {
  return (
    <div className="rounded-lg border bg-white text-slate-600 shadow-sm col-span-3">
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="text-lg font-semibold leading-none tracking-tight">
          Contactos
        </h3>
        <p className="text-sm text-muted-foreground">
          Estos son tus representantes de BAT
        </p>
      </div>
      <div className="p-6 pt-0">
        <div className="space-y-8">
          {contacts.map((contact) => (
            <Contact contact={contact} key={contact?._id} />
          ))}
        </div>
      </div>
    </div>
  );
}
