import React from 'react';
import useUser from '../../hooks/useUser';
import KPI, { IKPI } from './KPI';
import { Benefit, User } from '../../models';
import Contact from '../home/Contact';
import Contacts from './Contacts';
import Benefits from './BenefitsTable';
import formatMoney from '../../lib/formatMoney';

export interface DashboardData {
  accounts?: IKPI;
  availability?: IKPI;
  volume?: {
    aspirationalPremium: IKPI;
    premium: IKPI;
    valueForMoney: IKPI;
  };
  points?: number;
  notifications?: Array<Notification>;
  benefits?: Array<Benefit>;
  user?: User;
  representatives?: Array<User>;
  hasIllicit?: boolean;
}

interface DashboardProps {
  data: DashboardData;
}

export default function Dashboard({ data }: DashboardProps) {
  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-x-4 gap-y-8">
      {/* 1st row */}
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Cuentas por cobrar"
          data={data?.accounts}
          legend={`El objetivo era ${
            data?.accounts?.target > 1
              ? `${data?.accounts?.target ?? 0}%`
              : `${Number(data?.accounts?.target ?? 0).toFixed(0)}%`
          }`}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="% Vol Premium"
          data={data?.volume?.premium}
          legend={`El objetivo era ${
            data?.volume?.premium?.target > 1
              ? `${data?.volume?.premium?.target ?? 0}%`
              : `${Number(data?.volume?.premium?.target ?? 0).toFixed(0)}%`
          }`}
          pointPerAchievement="333"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="% Vol Aspirational Premium"
          data={data?.volume?.aspirationalPremium}
          legend={`El objetivo era ${
            data?.accounts?.target > 1
              ? `${data?.volume?.aspirationalPremium?.target ?? 0}%`
              : `${Number(
                  data?.volume?.aspirationalPremium?.target ?? 0
                ).toFixed(0)}%`
          }`}
          pointPerAchievement="333"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="% Vol Value for money"
          data={data?.volume?.valueForMoney}
          legend={`El objetivo era ${
            data?.accounts?.target > 1
              ? `${data?.volume?.valueForMoney?.target ?? 0}%`
              : `${Number(data?.volume?.valueForMoney?.target ?? 0).toFixed(
                  0
                )}%`
          }`}
          pointPerAchievement="333"
        />
      </div>
      {/* 2nd row */}
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="% Disponibilidad"
          data={data?.availability}
          legend={`El objetivo era ${
            data?.availability?.target > 1
              ? `${data?.availability?.target ?? 0}%`
              : `${Number(data?.availability?.target ?? 0).toFixed(0)}%`
          }`}
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Puntos disponibles"
          data={`${formatMoney(data?.user?.client?.points ?? 0)}`}
          legend="Cantidad de puntos disponibles para redimir"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Ilícitos"
          data={data?.hasIllicit ? 'Tiene ilícitos' : 'No tiene ilícitos'}
          legend="Presencia de ilícitos"
        />
      </div>
      <div className="sm:col-span-1 lg:col-span-3">
        <KPI
          title="Tareas pendientes"
          data={
            data?.notifications?.length > 0
              ? 'Hay pendientes'
              : 'No hay pendientes'
          }
          legend="Revise sus notificaciones"
        />
      </div>
      {/* 3rd row */}
      <div className="col-span-7">
        <Benefits
          benefits={data?.benefits ?? []}
          currentPoints={data?.user?.client?.points}
        />
      </div>
      <div className="col-span-5">
        <Contacts contacts={data?.representatives ?? []} />
      </div>
    </div>
  );
}
